import * as React from "react"
import Thanks from "../components/thanks"
import Seo from "../components/seo"
import Layout from "../components/app-layout"
import GlobalStyle from "../helpers/global-styles"
import Footer from "../components/footer"

const Legal = () => (
  <Layout pageName={'Contact'}>
    <GlobalStyle />
    <Thanks />
    <Footer />
  </Layout>
)

export const Head = () => <Seo title="Thanks" />

export default Legal
