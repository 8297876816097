import * as React from "react"
import useDeviceDetect from "../../hooks/use-device-detect"
import CategoryLink from "../categories/category-link"
import {
  Title,
  Description,
  Title2,
  TitleDescription,
  LargeTitle,
  Paragraph,
  ThanksPageWrapper,
  ContentWrapper,
  LeftColumn,
  LinkWrapper,
  OverwritedLink
} from "./index.style"

const Thanks = () => {
  const { isMobile } = useDeviceDetect()
  return (
    <>
      <ThanksPageWrapper>
        <LeftColumn>
          {!isMobile ? (
            <>
              <LargeTitle>Get in touch</LargeTitle>
              <br />
              <TitleDescription>
                To receive more information about Ruby, please fill out the
                following form, and we will be in touch with you shortly.
              </TitleDescription>
              <br />
              <br />
            </>
          ) : null}
          <LargeTitle>Leasing Gallery</LargeTitle>
          <br />

          <Paragraph>
            Address
            <br />
            <OverwritedLink
              href="https://goo.gl/maps/EcSDf3ayiGvpHLuE9"
              target={"_blank"}
              rel="noreferrer"
            >
              243 W 28th St, New York, NY 10001
            </OverwritedLink>
            <br />
            Hours
            <br />
            Mon-Sat: 10am-6pm
            <br />
            Sun: 11am-5pm
            <br />
            <br />
            Phone
            <br />
            212 551 RUBY
            <br />
            <br />
            Email
            <br />
            <OverwritedLink
              href="mailto:leasing@rubychelsea.com"
              target={"_blank"}
              rel="noreferrer"
            >
              leasing@rubychelsea.com
            </OverwritedLink>
          </Paragraph>
        </LeftColumn>
        <ContentWrapper>
          <Title>
            Thank you for your <br /> interest in Ruby.
          </Title>
          <Description>
            <div>One of our leasing agents will be</div>
            in touch soon.
          </Description>
        </ContentWrapper>
      </ThanksPageWrapper>
      <LinkWrapper>
        <CategoryLink title={"TEAM"} link={"/team"} />
      </LinkWrapper>
    </>
  )
}

export default Thanks
